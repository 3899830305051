import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import moment from 'moment';
import { getStatus, stateList } from '@/helpers/statusAgreement.js';
import { formatDate, showSnack } from '@/helpers/globalHelpers';
import {
	listAgreementTypes,
	deleteAgreementType,
} from '@/store/modules/agreementType/service/agreementType.service';

export default {
	name: 'AgreementTypeList',
	props: {},
	data: () => ({
		isLoading: false,
		dateRange: null,
		statusList: stateList,
		filters: {
			q: null,
			page: 1,
			date_to: null,
			fate_from: null,
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		headers: [
			{
				text: 'Tipo de documento:',
				value: 'name',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},

			{
				text: 'Fecha y hora:',
				value: 'created_at',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},

			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],
		agreementTypes: [],
		pagination: null,

		breadcrumbs: [
			{
				text: 'Inicio',
				link: true,
				exact: true,
				disabled: false,
				to: {
					name: 'Dashboard',
				},
			},
			{
				text: 'Abastecimiento',
				link: true,
				exact: true,
				disable: false,
				to: {
					name: 'AgreementHome',
				},
			},
			{
				text: 'Tipos de documento',
				disabled: true,
				href: '/',
			},
		],
	}),
	computed: {
		hasPermissionDelete: function () {
			return this.$store.getters['auth/hasActivity'](
				'abastecimiento',
				'listaTiposDocumento',
				'eliminarTiposDocumento'
			);
		},
	},
	watch: {},
	created() {
		this.getAgreementTypes();
	},
	methods: {
		...mapActions('agreement', ['listAgreementNotification', 'cleanNotifications']),
		...mapActions('agreementNotification', ['saveNotificationRead']),

		formatDate,
		getStatus,

		async getAgreementTypes() {
			try {
				this.isLoading = true;
				const response = await listAgreementTypes(this.filters);
				if (Array.isArray(response?.agreementTypes)) {
					this.agreementTypes = response?.agreementTypes;
					this.pagination = response?.pagination || null;
				}
			} catch (error) {
				showSnack(error.toString(), 'error');
			} finally {
				this.isLoading = false;
			}
		},

		async sendNotificationRead(item) {
			if (item?.id) {
				const data = {
					id: item.id,
					origin: 'agreement',
				};
				const { ok, error } = await this.saveNotificationRead(data);
				if (!ok) {
					this.showSnack(error, 'error');
				}
			}
		},

		showSnack(error, color = 'success') {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		openNotification(item) {
			this.sendNotificationRead(item);
			let name = 'ReadAgreement';
			if (this.$route?.name === 'SupplierNotification')
				name = 'SupplierPrecontractDetail';
			if (this.$route?.name === 'AgreementNotification') name = 'ReadAgreement';

			this.$router.push({
				name,
				params: {
					agreementId: item?.agreement_id,
				},
			});
		},

		listenFilters() {
			this.getAgreementTypes();
		},

		listenDatePicker(val) {
			moment.locale('es');
			this.filters.date_from = val[0] ? moment(val[0]).format('YYYY-MM-DD') : null;
			this.filters.date_to = val[1] ? moment().format('YYYY-MM-DD') : null;
			this.listenFilters();
		},

		deleteAgreementType: async function (agreementType) {
			try {
				const { isConfirmed } = await this.$swal.fire({
					title: '¿Estás seguro de que quieres eliminar?',
					text: '',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#e0e0e0',
					confirmButtonColor: '#0039a6',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí',
					reverseButtons: true,
				});
				if (isConfirmed) {
					await deleteAgreementType(agreementType?.id);
					showSnack('La operación se completó exitosamente', 'success');
					this.getAgreementTypes();
				}
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'error');
			}
		},
	},
	beforeDestroy() {
		this.cleanNotifications();
	},
	components: {},
};
