<template>
	<agreement-type-list />
</template>
<script>
import AgreementTypeList from '@/components/agreement/agreementType/AgreementTypeList/AgreementTypeList.vue';

export default {
	name: 'AgreementTypeView',
	components: { AgreementTypeList },
};
</script>
