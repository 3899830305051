import { http } from '@/store/api/http-common.js';

const listAgreementTypes = async (params) => {
	try {
		const { data } = await http.get('agreement-type', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTemplateByAgreementType = async (id, params) => {
	try {
		const { data } = await http.get(`agreement-type/${id}/template`, { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateAgreementTypes = async (id, payload) => {
	try {
		const { data } = await http.put(`/agreement-type/${id}`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createAgreementType = async (payload) => {
	try {
		const { data } = await http.post('agreement-type', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteField = async (id) => {
	try {
		const { data } = await http.delete(`agreement-type/field-agreement/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreementType = async (id) => {
	try {
		const { data } = await http.delete(`agreement-type/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

// const createAgreement = async (payload) => {
// 	try {
// 		const { data } = await http.post('agreement', payload);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const updateAgreement = async (payload) => {
// 	try {
// 		const { data } = await http.put(
// 			`agreement/${payload.agreement_id}/field-agreement`,
// 			payload
// 		);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const deleteAgreementAnnexFile = async (id) => {
// 	try {
// 		const { data } = await http.delete(`/agreement-annex/${id}`);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

export {
	listAgreementTypes,
	getTemplateByAgreementType,
	updateAgreementTypes,
	createAgreementType,
	deleteField,
	deleteAgreementType,
};
